/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP504M
 * 화면설명: 모바일청약진행 조회
 * 접근권한: 지점장
 * 작 성 일: 2022.06.20
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="모바일청약 진행" :show-title="true" type="subpage" action-type="search" @action-search-click="fn_Open514Pop">
    <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title"  v-if="lv_PrgPnsta === '1'">
                <span class="sum">총 {{tableData1.length}}건</span>
              </div>
              <div class="list-item__contents__title"  v-if="lv_PrgPnsta === '2'">
                <span class="sum">총 {{tableData2.length}}건</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-tab-box">
          <mo-tab-box default-idx="1">
            <!-- tab id loop -->
            <!-- <mo-tab-label idx="1" @click="fn_ChangeIdx(1)">완료건</mo-tab-label>
            <mo-tab-label idx="2" @click="fn_ChangeIdx(2)">중단건</mo-tab-label> -->
            <mo-tab-label idx="1" @click="fn_ChangeIdx('1')">완료건</mo-tab-label>
            <mo-tab-label idx="2" @click="fn_ChangeIdx('2')">중단건</mo-tab-label>
            <!-- tab id loop //-->
          </mo-tab-box>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list" v-if="lv_PrgPnsta === '1'">
          <mo-list :list-data="tableData1" v-if="tableData1.length !== 0">
            <template #list-item="{item}">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="name txtSkip">{{item.mnPrdtNm}}</span>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box">
                  <span>청약일 {{ item.elstPblYmd }}</span>
                </div>
                <div class="txt_right">
                  <span><strong>{{$bizUtil.numberWithCommas(item.contPrm)}}</strong></span>
                </div>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box">
                  <span>계약자 <b>{{item.mnContrNm}}</b></span><em>|</em>
                  <span>피보험자 {{item.insrdNm}}</span>
                </div>
                <div class="right-box"></div>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box" v-if="item.lv_fcData !== lv_FofLabel">
                  <span class="txtLine" @click="fn_Open511Pop(item.lv_fcData)">{{item.lv_fcData}}</span>
                </div>
                <div class="info_box" v-else>
                  <span class="txtLine">-</span>
                </div>
                <!-- <div class="txt_right">{{item.goThrough}}</div> -->
              </div>
            </div>
          </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list" v-if="lv_PrgPnsta === '2'">
          <mo-list :list-data="tableData2" v-if="tableData2.length !== 0">
            <template #list-item="{item}">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="name txtSkip">{{item.mnPrdtNm}}</span>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box">
                  <span>청약일 {{ item.insrOfrYmd }}</span>
                </div>
                <div class="txt_right">
                  <span><strong>{{$bizUtil.numberWithCommas(item.contPrm)}}</strong></span>
                </div>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box">
                  <span>계약자 <b>{{item.mnContrNm}}</b></span><em>|</em>
                  <span>피보험자 {{item.insrdNm}}</span>
                </div>
                <div class="right-box"></div>
              </div>
              <div class="list-item__contents__info">
                <div class="info_box" v-if="item.lv_fcData !== lv_FofLabel">
                  <span class="txtLine" @click="fn_Open511Pop(item.lv_fcData)">{{item.lv_fcData}}</span>
                </div>
                <div class="info_box" v-else>
                  <span class="txtLine">-</span>
                </div>
                <div class="txt_right">
                  <mo-button class="ns-btn-round blue" @click="fn_Open505Pop(item)">진행조회 {{item.goThrough}}</mo-button>
                </div>
              </div>
            </div>
          </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list no-data" v-if="tableData2.length === 0 && lv_PrgPnsta === '2'">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span v-if="searchRes2 === '1'">상세조회 조건을 설정해 주세요.</span>
                <span v-else>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list no-data" v-if="tableData1.length === 0 && lv_PrgPnsta === '1'">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span v-if="searchRes1 === '1'">상세조회 조건을 설정해 주세요.</span>
                <span v-else>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import DateUtil from '@/ui/sp/common/exDateUtils'
  import MSPAP514P from '@/ui/ap/MSPAP514P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  import MSPAP505P from '@/ui/ap/MSPAP505P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP504M",
    screenId: "MSPAP504M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP504M')
      this.fn_BottomAlert()
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        rowPopupData: {},
        lv_IsFirst: true,
        searchRes1: '1',
        searchRes2: '1',
        lv_FofLabel: '선택하세요',
        lv_IsChange: true,
        lv_UserEno: null,
        initSearchFlag: false,
        isLoadingStatus: false,
        pSElstIntrsCseReqSVO1: {},
        pSElstIntrsCseReqSVO2: {},
        tableData1: [],
        tableData2: [],
        orgTableData1: [],
        orgTableData2: [],
        holyDayChkDateArry: [], // 현재날짜+10일까지 날짜정보 배열
        eltrnSignTotCnt: 0,
        eltrnSignPageNo: 1,
        stopListPageNo: 1,
        stopListVuchId: '', // 중단건 영수증번호
        searchFlag: false, // 중단건 조회 여부
        searchFlag2: false, // 완료건 조회 여부
        guideDate1: '',
        isEmpty: false,
        mockData: [],
        mockData2: [],
        nodataCd: 1, // 1: 데이터가 없습니다. 2: 검색결과가 없습니다
        searchKeyword: '',
        lv_PrgPnsta: '1', // 모바일청약 진행현황
        lv_PrgPnstaItem:[], // 모바일청약 진행현황
        lv_PrgPnstaItems: [ // 모바일청약 진행현황
          { key: '01', label: '완료건' },
          { key: '02', label: '중단건' }
        ],
        lv_SearchData: {}, // 전달받은 검색조건
        lv_popup514: {}, // MSPAP514P 상세조회 팝업
        lv_popup511: {}, // MSPAP511P FC전화/문자 팝업
        lv_popup505: {}, // MSPAP505P 진행조회 팝업
        srchPopupShowYn: false,
        lv_AlertPopup: {}
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      checkCount1 () {
        return this.tableData1.filter(item => item.checked).length
      },
      checkCount2 () {
        return this.tableData2.filter(item => item.checked).length
      }
    },
    /***********************************************************************************
     * watch 정의 영역
    ***********************************************************************************/
    watch: {
    // 'optSelect1.0.label' () {
    //   if (this.optSelect1[0].label === '중단건') {
    //     // 2020.03.05 중단건 조회시 [계약서류발송] 영역 제거

    //     if (this.searchFlag) return
    //     this.fn_ServiceData('S2')
    //   }
    // },
      lv_PrgPnsta (newVal, oldVal) {
        console.log('watch', this.lv_PrgPnsta)
        if (newVal !== oldVal && Object.keys(this.lv_SearchData).length !== 0) {
          this.fn_SetData(this.lv_SearchData)
        }
      },
      checkCount1 (o) {
        if (o === this.tableData1.length) {
          this.isOneOrMoreCheck = true
        } else {
          this.isOneOrMoreCheck = false
        }
      },
      checkCount2 (o) {
        if (o === this.tableData2.length) {
          this.isOneOrMoreCheck = true
        } else {
          this.isOneOrMoreCheck = false
        }
      },
      optSelect1 (o) {
        if (o[0].key === '01') { // 완료건
          // 완료건 들어왔고 변경된건데 데이터 없을경우 1-> 컨설턴트 선택여부 따라
          if (this.tableData1.length === 0) {
            if (this.lv_FofCnslt.key !== '0') {
              this.fn_ServiceData('S1')
            }
          } else {
            // 데이터 있을경우 컨설턴트 데이터와 동일한건지. 따라
            if (this.tableData1[0].lv_fcData !== this.lv_FofCnslt.label) {
              // 다르면 초기화
              this.fn_ServiceData('S1')
            }
          }
        } else if (o[0].key === '02') { // 중단건
          // 중단건 들어왔고 변경된건데 데이터 없을경우 1-> 컨설턴트 선택여부 따라
          if (this.tableData2.length === 0) {
            if (this.lv_FofCnslt.key !== '0') {
              this.fn_ServiceData('S2')
            }
          } else {
            // 데이터 있을경우 컨설턴트 데이터와 동일한건지. 따라
            if (this.tableData2[0].lv_fcData !== this.lv_FofCnslt.label) {
              // 다르면 초기화
              alert(this.tableData2[0].lv_fcData + ' ' + this.lv_FofCnslt.label)
              this.fn_ServiceData('S2')
            }
          }
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_ChangeIdx
       * 설명       : 완료건, 중단건 Tab Change
       ******************************************************************************/
      fn_ChangeIdx (str) {
        if (str === '1') {
          this.lv_PrgPnsta = '1'
        } else {
          this.lv_PrgPnsta = '2'
        }
      },
      /******************************************************************************
      * Function명 : fn_SetData
      * 설명       : 데이터 초기화
      * 인자       : N/A
      * Return     : N/A
      ******************************************************************************/
      fn_SetData (pData) {
        // this.lv_UserEno = o.key
        // if (o.key === '0') {
        //   return
        // }
        // selMobpsElstList -> 완료목록 조회
        // selMobpsIntrsCseList -> 중단목록 조회
        if (this.lv_PrgPnsta === '1') {
          this.fn_ServiceData('S1', pData)
        } else {
          this.fn_ServiceData('S2', pData)
        }
      },
      /******************************************************************************
      * Function명  : fn_ServiceData
      * 설명        : 서비스 호출 함수 정의
      ******************************************************************************/
      fn_ServiceData (serviceName, pData) {
        console.log('fn_ServiceData', pData)
        if (pData.cnslt.key === '0') {
          return
        }
        // if (this.searchFlag && ( serviceName === 'S1' || serviceName === 'S2') ) return
        this.isLoadingStatus = true
        let pParams = {}
        pParams.srnId = this.$options.screenId // 대표 화면 명
        let lv_Vm = this
        let trnstId = ''
        switch (serviceName) {
          case 'S1': // 모바일청약 완료목록조회
            lv_Vm.pSElstIntrsCseReqSVO1 = {
              // minusDay: '15',
              // 2020.03.05 최주연 프로
              // 완료건 조회기간 변경 15일 -> 2년
              minusDay: '730',
              pageNo: lv_Vm.eltrnSignPageNo,
              pageSize: 10,
              userEno: pData.cnslt.key
              // userEno: pData.cnslt.label.substring(5, 15)
            }
            pParams = lv_Vm.pSElstIntrsCseReqSVO1
            console.log('완료목록조회 :::', pParams)
            trnstId = 'txTSSPS50S1'
            break
          case 'S2': // 중단건 조회
            lv_Vm.pSElstIntrsCseReqSVO2 = {
              predVuchId: lv_Vm.stopListVuchId,
              pageNo: lv_Vm.stopListPageNo,
              pageSize: 10,
              bef1SalesYmd: lv_Vm.fn_currntDayPlusDateChk(1),
              // bef5SalesYmd: lv_Vm.fn_currntDayPlusDateChk(5)
              bef5SalesYmd: '20200801',
              userEno: pData.cnslt.key
              // userEno: pData.cnslt.label.substring(5, 15)
            }
            pParams = lv_Vm.pSElstIntrsCseReqSVO2
            console.log('중단건조회 :::', pParams)
            trnstId = 'txTSSPS50S2'
            break
          case 'S1_N':
            if ( lv_Vm.tableData1.length >= lv_Vm.eltrnSignTotCnt || lv_Vm.eltrnSignTotCnt <= 10 ) {
              return
            }
            lv_Vm.pSElstIntrsCseReqSVO1.pageNo = ++lv_Vm.eltrnSignPageNo
            pParams = lv_Vm.pSElstIntrsCseReqSVO1
            trnstId = 'txTSSPS50S1'
            break
          case 'S2_N':
            if ( lv_Vm.tableData2.length >= lv_Vm.stopListTotCnt || lv_Vm.stopListTotCnt <= 10 ) {
              return
            }
            lv_Vm.pSElstIntrsCseReqSVO2.pageNo = ++lv_Vm.stopListPageNo
            pParams = lv_Vm.pSElstIntrsCseReqSVO2
            trnstId = 'txTSSPS50S2'
            break
        } // end switch
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            switch (serviceName) {
              case 'S1':
              case 'S1_N':
                let t_rslt = response.body.pSElstIntrsCseListDVO
                let t_rowIndex = lv_Vm.tableData1.length
                if (t_rslt && t_rslt.length > 0) {
                  lv_Vm.searchFlag2 = true
                  if (serviceName === 'N' && lv_Vm.tableHeader.isSelectAll) { // 더보기 조회 했을때 선택하세요 선택 컨트롤
                    lv_Vm.tableHeader.isSelectAll = false
                  }
                  let sortArray = []
                  t_rslt.forEach(pIteam => {
                    pIteam.id = t_rowIndex++
                    pIteam.step = 0
                    pIteam.lv_fcData = pData.cnslt.label.slice(0, pData.cnslt.label.length)
                    pIteam.checked = false
                    // 설계명에 삼성생명, 삼성 제거 처리
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성생명', '')
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성', '')
                    pIteam.mnPrdtNm = pIteam.mnPrdtNm.trim()
                    pIteam.ofrMmgNm = '-'
                    pIteam.prgPhseNm = '-'
                    // pIteam.joinDate = pIteam.elstPblYmd
                    // pIteam.elstPblYmd = pIteam.elstPblYmd.trim().length > 0 ? DateUtil.fn_DateFormat(pIteam.elstPblYmd, 'MM-dd') : '-'
                    // 191115 현장접수 청약일 조건변경
                    // 계약일 익영업일까지 접수 가능함
                    // 1) 초회보험료납입코드 1:실시간이체 -> 계약일=출금동의일자(DFRA_CNSNT_YMD)
                    // 2) 초회보험료납입코드 4:가상계좌입금 -> 계약일=전자서명발행일자(청약일자)(ELST_PBL_YMD)
                    if (pIteam.frtmPrmPadMthCd === '1') {
                      pIteam.joinDate = pIteam.dfraCnsntYmd // 1) 초회보험료납입코드 1:실시간이체 -> 계약일=출금동의일자(DFRA_CNSNT_YMD)
                    } else if (pIteam.frtmPrmPadMthCd === '4') {
                      pIteam.joinDate = pIteam.elstPblYmd // 2) 초회보험료납입코드 4:가상계좌입금 -> 계약일=전자서명완료일자(ELST_CNCLN_YMD)
                    }
                    // 191231 ASR191200905 청약일자 공백時 전자서명 완료건 목록 미 표시
                    pIteam.elstPblYmd = (pIteam.joinDate && pIteam.joinDate.trim().length > 0) ? DateUtil.fn_DateFormat(pIteam.joinDate, 'MM-dd') : '-'
                    // 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
                    if (pIteam.isuMthScCd === '01') {
                      pIteam.fstIsuMthScNm = 'E-mail'
                    } else if (pIteam.isuMthScCd === '02') {
                      pIteam.fstIsuMthScNm = '서면'
                    } else if (pIteam.isuMthScCd === '03') {
                      pIteam.fstIsuMthScNm = '모바일(e)'
                    } else if (pIteam.isuMthScCd === '04') {
                      pIteam.fstIsuMthScNm = '모바일'
                    } else {
                      pIteam.fstIsuMthScNm = '미선택'
                    } // end else if
                    // 현장 접수 완료가 'Y'이고 초회보험료이체여부과 'N'일 때
                    // 초회보험료이체일자 + 1 보다 같거나 작을 때 까지 현장출금 버튼 활성화
                    if (pIteam.elstPrgStatCd === '40') { // 전자서명진행상태코드(elstPrgStatCd) : 40:이체완료
                      pIteam.prgPhseNm = '-' // 이체완료
                    } else {
                      if (pIteam.scnAcpnCmpltYn === 'Y') { // 현장접수완료여부(scnAcpnCmpltYn)
                        pIteam.prgPhseNm = '-' // 접수완료
                        // 현장출금 여부
                        if (pIteam.frtmPrmCmpltYn !== 'Y') { // 초회보험료이체여부(frtmPrmCmpltYn)
                          if (pIteam.scnAcpnCmpltYmd === DateUtil.fn_CurrentDate()) { // 현장접수완료일자(scnAcpnCmpltYmd), 현장출금버튼은 현장접수완료일 당일만 표시
                            if (pIteam.frtmPrmPadMthCd === '1') { // 초회보험료납입방법코드(frtmPrmPadMthCd) 1:실시간이체
                              if (pIteam.frtmPrmTrsfYmd === '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd), 접수 결과 미 수신건 재접수 시 현장출금 예외처리
                                pIteam.ofrMmgNm = '-'
                              } else {
                                pIteam.ofrMmgNm = '접수/출금'
                              }
                            } else {
                              pIteam.ofrMmgNm = '-'
                            }
                          } else {
                            pIteam.ofrMmgNm = '-'
                          } // end else if
                        } // end if
                        // 현장접수/지점취소의 경우 버튼 표시
                        if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate) { // 워크데이 기준 D+1일 현장접수 버튼 표시
                          if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                            pIteam.ofrMmgNm = '접수/출금'
                          } else pIteam.ofrMmgNm = '-'
                        }
                        if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                          pIteam.ofrMmgNm = '접수불가'
                        }
                      } else { // 현장접수완료여부(scnAcpnCmpltYn) : N
                        if (pIteam.elst5PhseCmpltYn === 'Y') { // 전자서명5단계완료여부(elst5PhseCmpltYn)
                          pIteam.prgPhseNm = '-' // 전자서명완료
                          pIteam.ofrMmgNm = '-'
                          // 워크데이 기준 D+1일 현장접수 버튼 표시
                          // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
                          //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
                          if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate && DateUtil.fn_CurrentDate().substr(0, 4) === pIteam.joinDate.substr(0, 4)) {
                            if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                              pIteam.ofrMmgNm = '접수/출금'
                            } else pIteam.ofrMmgNm = '-'
                          }
                          if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                            pIteam.ofrMmgNm = '접수불가'
                          }
                          if (pIteam.scnAcpnPsbYn !== 'Y' && pIteam.scnAcpnPsbYn !== 'N') { // 5단계가 완료되어도, 현장접수여부가 확정되지 않은 경우 재처리 필요
                            pIteam.ofrMmgNm = '-'
                            pIteam.prgPhseNm = '재처리'
                          }
                        } else if (pIteam.elst2PhseCmpltYn === 'Y') { // 전자서명2단계완료여부(elst2PhseCmpltYn)
                          pIteam.prgPhseNm = '재처리'
                          pIteam.ofrMmgNm = '-'
                          // 워크데이 기준 D+1일 현장접수 버튼 표시
                          // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
                          //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
                          if (lv_Vm.fn_currntDayPlusDateChk(1) <= pIteam.joinDate && DateUtil.fn_CurrentDate().substr(0, 4) === pIteam.joinDate.substr(0, 4)) {
                            if ( pIteam.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                              pIteam.ofrMmgNm = '접수/출금'
                            } else pIteam.ofrMmgNm = '-'
                          }
                          if (pIteam.scnAcpnPsbYn === 'N') { // 접수불가건
                            pIteam.ofrMmgNm = '접수불가'
                          }
                        } else {
                          pIteam.ofrMmgNm = '-'
                          pIteam.prgPhseNm = '재처리'
                        }
                      } // end else if
                    }
                    // 1월 7일 이전 발행건의 [접수/출금] 버튼 숨김 처리
                    if (pIteam.joinDate < '20190107') {
                      pIteam.ofrMmgNm = '-'
                      pIteam.prgPhseNm = '-'
                    }
                    // 1월 7일 이전 발행건의 [접수/출금] 버튼 숨김 처리
                    sortArray.push(pIteam)
                  })
                  if ( serviceName === 'S1' ) {
                    lv_Vm.tableData1 = sortArray
                    lv_Vm.orgTableData1 = sortArray.slice(0, sortArray.length)
                  } else {
                    sortArray.forEach(item => {
                      // lv_Vm.orgTableData1.push(item)
                      lv_Vm.tableData1.push(item)
                      lv_Vm.orgTableData1.push(item)
                      // console.log(item.mnContrNm + ',' + item.insrdNm + ',' + item.vuchId)
                    })
                  }
                  lv_Vm.eltrnSignTotCnt = response.body.totCnt
                  lv_Vm.fn_Arrange(pData)
                } else {
                  if (serviceName === 'S1') lv_Vm.fn_TableStat(pData) // 조회결과 없음 처리
                }
                break
              case 'S2':
              case 'S2_N':
                if ( response.body !== null ) {
                  lv_Vm.searchFlag = true
                  let t_rslt2 = response.body.pSElstIntrsCseListDVO
                  if (t_rslt2 && t_rslt2.length > 0) {
                    let sortArray2 = []
                    t_rslt2.forEach(pIteam => {
                      // 청약일
                      pIteam.insrOfrYmd = pIteam.insrOfrYmd.trim().length > 0 ? DateUtil.fn_DateFormat(pIteam.insrOfrYmd, 'MM-dd') : '-'
                      // 상품명 : 설계명에 삼성생명, 삼성 제거 처리
                      pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성생명', '')
                      pIteam.mnPrdtNm = pIteam.mnPrdtNm.replace('삼성', '')
                      pIteam.mnPrdtNm = pIteam.mnPrdtNm.trim()
                      pIteam.lv_fcData = pData.cnslt.label.slice(0, pData.cnslt.label.length)
                      pIteam.checked = false
                      // 진행현황
                      pIteam.prcssStatus = '진행조회'
                      sortArray2.push(pIteam)
                    }) // t_rslt2.forEach 종료
                    if ( serviceName === 'S2' ) {
                      lv_Vm.tableData2 = sortArray2
                      lv_Vm.orgTableData2 = sortArray2.slice(0, sortArray2.length)
                    } else {
                      sortArray2.forEach(item => {
                        // lv_Vm.orgTableData2.push(item)
                        lv_Vm.tableData2.push(item)
                        console.log(lv_Vm.tableData2)
                        lv_Vm.orgTableData2.push(item)
                      })
                    }
                    lv_Vm.stopListTotCnt = response.body.totCnt
                    lv_Vm.fn_Arrange(pData)
                  } else {
                    if (serviceName === 'S2') lv_Vm.fn_TableStat(pData) // 조회결과 없음 처리
                  }
                }
                break
            }
            lv_Vm.isLoadingStatus = false
          })
          .catch(function (error) {
            lv_Vm.fn_TableStat(pData)
            lv_Vm.searchFlag = false
            window.vue.error(error)
          })
      },
      /************************************************************************************************
       * Function명  : fn_TableStat
       * 설명        : 데이터 없는 페이지 설정
       ************************************************************************************************/
      fn_TableStat (pData) {
        console.log(pData)
        if (this.lv_PrgPnsta === '1') {
          this.$set(this, 'tableData1', [])
          this.searchRes1 = this.initSearchFlag ? '1' : '2' // true: 초기조회(진행건없음), flase:버튼조회(검색결과없음)
        } else if (this.lv_PrgPnsta === '2') {
          this.$set(this, 'tableData2', [])
          this.searchRes2 = this.initSearchFlag ? '1' : '2' // true: 초기조회(진행건없음), flase:버튼조회(검색결과없음)
        } // end else if
      },
      /************************************************************************************************
       * Function명  : fn_currntDayPlusDateChk
       * 설명        : 현재날짜-10일까지 날짜정보 처리 -> 공휴일을 제외한 워킹데이 처리할때 사용하면 됨
       ************************************************************************************************/
      fn_currntDayPlusDateChk (cnt) {
        let dayCnt = 0
        let holyDayChkDate = ''
        let i = this.holyDayChkDateArry.length - 1
        for ( i; i > 0; i-- ) {
          if ( this.holyDayChkDateArry[i].holyCd === '00') {
            dayCnt++
          }
          if ( dayCnt === cnt ) {
            holyDayChkDate = this.holyDayChkDateArry[i].slrcYmYmd
            break
          }
        }
        return holyDayChkDate
      },
      /******************************************************************************
      * Function명 : fn_Arrange
      * 설명       : 조회키 클릭시 정렬
      * 인자       : N/A
      * Return     : N/A
      ******************************************************************************/
      fn_Arrange (pData) {
        let lv_Vm = this
        if (lv_Vm.lv_PrgPnsta === '1') {
          if (pData.priority.length === 0) return
          // 완료건
          lv_Vm.tableData1.sort(function (a, b) {
            if (pData.priority[0].key === '01') { // 높은 순으로.
              if ( a.contPrm !== b.contPrm) {
                return Number(a.contPrm) > Number(b.contPrm) ? -1 : 1
              } else {
                return a.elstPblYmd < b.elstPblYmd ? 1 : -1
              }
            } else {
              if ( a.contPrm !== b.contPrm) {
                return Number(a.contPrm) < Number(b.contPrm) ? -1 : 1
              } else {
                return a.elstPblYmd < b.elstPblYmd ? 1 : -1
              }
            }
          })
        } else if (lv_Vm.lv_PrgPnsta === '2') {
          if (pData.priority.length === 0) return
          // 중단건 insrOfrYmd
          lv_Vm.tableData2.sort(function (a, b) {
            if (pData.priority[0].key === '01') { // 높은 순으로.
              if (a.contPrm !== b.contPrm) {
                return Number(a.contPrm) > Number(b.contPrm) ? -1 : 1
              } else {
                return a.insrOfrYmd < b.insrOfrYmd ? 1 : -1
              }
            } else {
              if (a.contPrm !== b.contPrm) {
                return Number(a.contPrm) < Number(b.contPrm) ? -1 : 1
              } else {
                return a.insrOfrYmd < b.insrOfrYmd ? 1 : -1
              }
            }
          })
        }
      },
      /******************************************************************************
       * Function명 : fn_Open514Pop
       * 설명       : 상세조회조건 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_Open514Pop () {
        console.log('this.lv_SearchData', Object.keys(this.lv_SearchData).length)
        this.srchPopupShowYn = true // 상세조회조건 활성화시 true
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP504M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)
                this.lv_IsFirst = false
                console.log('substring', pData.cnslt.label.substring(5, 15))
                Object.assign(this.lv_SearchData, pData)
                this.fn_SetData(pData)
                // this.fn_Arrange(pData)
                // this.fn_InnerSrch(pData)
                console.log('this.lv_SearchData', this.lv_SearchData)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        } else {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
            properties: {
              title: "상세조회조건",
              pSrnId: 'MSPAP504M',
              userInfo: this.getStore('userInfo').getters.getUserInfo,
              pSearchData: this.lv_SearchData
            },
            listeners: {
              onPopupConfirm: (pData) => {
                console.log("onPopUpClose")
                this.$bottomModal.close(this.lv_popup514)
                this.fn_SetData(pData)
                Object.assign(this.lv_SearchData, pData)
                this.lv_IsFirst = false
                console.log('this.lv_SearchData', this.lv_SearchData)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup514)
              }
            }
          })
        }
      },
      /******************************************************************************
       * Function명 : fn_Open511Pop
       * 설명       : FC문자/전화 팝업 호출
       * 
       ******************************************************************************/
      fn_Open511Pop (cnsltInfo) {
        this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: cnsltInfo.substring(0, 4),
            pCnsltNo: cnsltInfo.substring(5, 15),
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm")
              this.$bottomModal.close(this.lv_popup511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup511)
            }
          }
        })
      },
      fn_Open505Pop (pData) {
        console.log('pData ::', pData)
        this.rowPopupData = pData
        this.lv_popup505 = this.$bottomModal.open(MSPAP505P, {
          properties: {
            title: "진행현황",
            pSrnId: 'MSPAP504M',
            rowPopupData: pData
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm")
              this.$bottomModal.close(this.lv_popup505)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup505)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 화면 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_Init () {
        this.tableData1.slice(0)
        this.tableData1 = []
        this.tableData2.slice(0)
        this.tableData2 = []
        this.orgTableData1.slice(0)
        this.orgTableData1 = []
        this.orgTableData2.slice(0)
        this.orgTableData2 = []
      },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (this.srchPopupShowYn) {
          this.lv_SearchData = {}
          this.fn_Init()
          this.srchPopupShowYn = false
          this.lv_IsFirst = true
          this.searchRes1 = '1'
          this.searchRes2 = '1'
          this.fn_BottomAlert()
        } else {
          this.$router.go(-1)
        }
      },
    /******************************************************************************
    * Function명 : fn_BottomAlert
    * 설명       : 단순알림 팝업 호출
    ******************************************************************************/
      fn_BottomAlert () {
        this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '컨설턴트를 먼저 선택해 주세요.'
            //preventTouch: true
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_Open514Pop()
              this.$bottomModal.close(this.lv_AlertPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPopup);
            }
          }
        },
        {properties: {preventTouchClose : false}}
      )},
    },
  }
</script>
<style scoped>
</style>